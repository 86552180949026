<template>
  <div class="revenueCenter">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">收益中心</div>
      <div class="rightBtn"></div>
    </div>
    <div class="walletBox">
      <div class="walletTitle">
        <div class="walletLine"></div>
        <div class="incomeTitle"></div>
      </div>
      <div class="bottomBox">
        <div class="amountBox">
          <div class="amount">
            <svg-icon class="goldenBeans" iconClass="goldenBeanIcon" />{{
              walletInfo.income
            }}
          </div>
          <!-- <div class="withdrawalAmount">
            可提现余额 {{ walletInfo.income }} 元
          </div> -->
        </div>

        <!-- <div
          class="withdrawBtn"
          :class="{ activeBtn: walletInfo.income > 0 }"
          @click="jumpWithdraw"
        >
          收益提现
        </div> -->
      </div>
    </div>
    <div class="tip">
      温馨提示：圈子发布帖子所得收益金豆，可在妖精平台上进行消费，无法提现，请悉知！
    </div>
    <div class="title"></div>
    <div class="revenueList">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="revenueItem" v-for="item in list" :key="item.id">
          <div class="avatar">
            <ImgDecypt class="avatarImg" :src="item.cashierAvatar" round />
          </div>
          <div class="incomeInfo">
            <div class="userName">{{ item.cashierName }}</div>
            <div class="dateBox">
              <svg-icon
                class="sexIcon"
                iconClass="maleIcon"
                v-if="item.gender == 'male'"
              />
              <svg-icon
                class="sexIcon"
                iconClass="womanIcon"
                v-if="item.gender == 'female'"
              />
              <div class="date">{{ item.createdAt | orderDate }}</div>
            </div>
            <div class="desc">
              {{ item.desc }} <span>收益{{ item.actualAmount }}金豆</span>
            </div>
          </div>
          <div class="postImg">
            <ImgDecypt class="vidCover" :src="item.vidCover" />
          </div>
          <!-- <div class="userInfo">
            <div class="avatar">
              <ImgDecypt class="avatarImg" :src="item.cashierAvatar" round />
            </div>
            <div class="userName">{{ item.cashierName }}</div>
            <div class="userOpt">{{ item.desc }}</div>
          </div>
          <div class="postInfo">
            <div class="postDesc ellipsis">{{ item.vidTitle }}</div>
            <div class="postAmountBox">
              <svg-icon class="goldIcon" iconClass="revenueGold" />
              <div class="postAmount">{{ item.actualAmount }}</div>
            </div>
          </div>
          <div class="date">{{ item.createdAt | orderDate }}</div> -->
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryIncomes } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      walletInfo: {},
    };
  },
  created() {
    this.queryBalance();
    this.getList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryIncomes, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.queryBalance();
      this.getList("refresh");
    },
    jumpWithdraw() {
      // if (this.walletInfo.income > 0) {
      //     this.$router.push("/withdraw")
      // }
      this.$router.push("/withdraw");
    },
  },
};
</script>

<style lang="scss" scoped>
.revenueCenter {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 47px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 64px;
      font-size: 14px;
      color: #999999;
    }
  }

  .walletBox {
    height: 97px;
    border-radius: 8px;
    margin: 12px 16px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;

    .walletTitle {
      display: flex;
      align-items: center;
      margin: 12px 0 14px;
      .walletLine {
        width: 8px;
        height: 22px;
        border-radius: 0 6px 6px 0;
        margin-right: 12px;
        background: #333333;
      }
      .incomeTitle {
        width: 56px;
        height: 20px;
        background: url("../../../assets/png/incomeTitle.png");
        background-size: 100% 100%;
      }

      span {
        font-size: 12px;
        color: #999999;
      }
    }

    .bottomBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 12px 0 20px;
      box-sizing: border-box;
      .amountBox {
        .withdrawalAmount {
          color: #999;
          margin-left: 34px;
          margin-top: 3px;
          font-size: 12px;
        }
      }

      .amount {
        font-size: 24px;
        font-weight: 900;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #333;
        font-family: "YEFONTAoYeHei";
        .goldenBeans {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-top: 4px;
        }
      }

      .withdrawBtn {
        width: 76px;
        height: 27px;
        border-radius: 27px;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background: #999999;
      }
      .activeBtn {
        background: #94d6da;
      }
    }
  }
  .tip {
    color: #777;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    width: 355px;
    margin: 0 auto;
  }

  .title {
    font-size: 18px;
    margin: 10px 12px 16px;
    width: 58px;
    height: 20px;
    background: url("../../../assets/png/incomeSubTitle.png");
    background-size: 100% 100%;
  }

  .revenueList {
    padding: 0 12px;
    box-sizing: border-box;
    height: calc(100% - 217px);
    overflow-y: auto;

    .revenueItem {
      display: flex;
      justify-content: space-between;
      padding-bottom: 14px;
      margin-bottom: 14px;
      border-bottom: 1px solid #e6e6e6;
      .avatar {
        width: 40px;
        height: 40px;
      }
      .incomeInfo {
        flex: 2;
        margin: 0 10px;
        .userName {
          color: #666;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        .dateBox {
          display: flex;
          align-items: center;
          .sexIcon {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
          .date {
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            color: #999;
          }
        }
        .desc {
          color: #333;
          text-align: justify;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            color: #b09fd9;
          }
        }
      }
      .postImg {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        overflow: hidden;
        :deep(img) {
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
